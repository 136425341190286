import React, { useCallback, useMemo, useState } from 'react';
import { useGetSources } from '../../../hooks/useGetSources';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../hooks/useRequester';
import { useSerializer } from '../../../hooks/useSerializer';
import { useToast } from '../../../hooks/useToast';
import { ActiveTabs, AssessmentTag, Status, Tables } from '../../../interfaces';
import { updateStatus, updateStatusByFilter } from '../../../services';
import { useViews } from '../../../store/hooks/customViewsHook';
import { useTableStore } from '../../../store/hooks/tablesHook';
import {
  ApprovalButtonsProps,
  ApprovalProps,
  MappedPropsFn,
  OnUpdateByFilterFnProps,
  OnUpdateFnProps,
} from './ApprovalButtons.types';
import { Approve } from './Approve/Approve';
import { Deny } from './Deny/Deny';
import { ApprovalButtonsContainer } from './styles';

export const ApprovalButtons: React.FC<ApprovalButtonsProps> = ({
  mode,
  type,
  button,
  counter,
  selected,
  portal,
  callbackFn,
}) => {
  const { post, get } = useRequester();
  const { toast } = useToast();
  const { activetags } = useViews();

  const { user, permissions, configs } = useGetUserPreferences();
  const { tables } = configs;
  const { onboardingRule } = useGetSources();

  const { serializer } = useSerializer();

  const {
    filters: storedFilters,
    search: storedSearch,
    selectable,
  } = useTableStore(Tables.PENDING);

  const isAsyncMode = selectable.requestType === 'async';

  const [isLoading, setIsLoading] = useState({ APPROVED: false, DENIED: false });

  const approvalProps = useMemo(
    () => ({ mode, type, button, counter, selected } as ApprovalProps),
    [mode, type, button, counter, selected]
  );

  const { isRecommended } = useMemo(() => {
    const tab = sessionStorage.getItem(ActiveTabs.ACTIVE_TAB_KEY) as Tables | null;
    const { tag } = activetags.find(({ table }) => table === tab) ?? activetags[0];

    return { isRecommended: tag === AssessmentTag.RECOMMENDED };
  }, [activetags]);

  const mapped = useCallback(
    ({ status, attributes }: MappedPropsFn) => ({
      bees_account_id: attributes.beesAccountId,
      status,
      user_id: user.name,
      ...(attributes.has && {
        attributes: [
          { name: 'ONBOARDING_BYPASS', value: attributes.value.toString() },
          { name: 'POC_DOCUMENT', value: attributes.pocDocument },
        ],
      }),
    }),
    [user.name]
  );

  const mappedByFilter = useCallback(
    (status: Status, byPass: boolean) => {
      const params =
        storedFilters.value && new URLSearchParams(serializer(storedFilters.value).filters);

      const filterParams = {
        status: tables.pending.status.join(','),
        poc_or_id: storedSearch.value,
        recommended: isRecommended,
        range: params?.get('range'),
        date: params?.get('date'),
        tag: params?.get('tag'),
      };

      return {
        status,
        user_id: user.name,
        filter: filterParams,
        request_on_boarding: byPass,
        skip_bees_account_ids: selectable.exclusions.mapped,
      };
    },
    [
      isRecommended,
      user.name,
      tables.pending.status,
      storedFilters.value,
      storedSearch.value,
      selectable.exclusions.mapped,
    ]
  );

  const onUpdate = async ({ params, toasts, status }: OnUpdateFnProps) => {
    const response = await updateStatus({ api: { post }, params });

    if (response?.data?.request?.status === 412) {
      toast.error({ message: 'approval.errors.412.description' });

      return;
    }

    if (!response?.success) {
      toast.error({ message: 'errors.generic' });

      return;
    }

    if (status === Status.APPROVED) {
      toast.success({ message: toasts.message, attributes: toasts.attributes });
    } else {
      toast.error({ message: toasts.message, attributes: toasts.attributes });
    }

    if (callbackFn) callbackFn();
  };

  const onUpdateByFilter = async ({ params, toasts }: OnUpdateByFilterFnProps) => {
    const response = await updateStatusByFilter({ api: { post }, params });

    if (response?.data?.request?.status === 412) {
      toast.error({ message: 'approval.errors.412.description' });

      return;
    }

    if (!response?.success) {
      toast.error({ message: 'errors.generic' });

      return;
    }

    get({
      path: `/v1/ws-events/restart-pooling?fileId=${response?.data?.id}&origin=ASYNC_UPDATE`,
    });

    toast.info({ message: toasts.message, attributes: toasts.attributes });
    if (callbackFn) callbackFn();
  };

  const onApproval = useCallback(
    async (status: Status, value?: boolean) => {
      setIsLoading((prev) => ({ ...prev, [status]: true }));
      const hasAttr = onboardingRule && status === Status.APPROVED;

      if (mode === 'multiple') {
        const params = selected.map(({ beesAccountId, pocDocument }) => {
          return mapped({
            status,
            attributes: { has: hasAttr, value, beesAccountId, pocDocument },
          });
        });

        onUpdate({
          status,
          params,
          toasts: {
            message: `approval.toasts.${status}.${counter > 1 ? 'multiple' : 'single'}`,
            attributes: {
              value: counter > 1 ? counter : selected[0].pocName,
            },
          },
        }).finally(() => setIsLoading((prev) => ({ ...prev, [status]: false })));

        return;
      }

      const { beesAccountId, pocDocument } = selected;

      onUpdate({
        status,
        params: [
          mapped({ status, attributes: { has: hasAttr, value, beesAccountId, pocDocument } }),
        ],
        toasts: {
          message: `approval.toasts.${status}.single`,
          attributes: { value: selected.pocName },
        },
      }).finally(() => setIsLoading((prev) => ({ ...prev, [status]: false })));
    },
    [onboardingRule, selected, counter]
  );

  const onApprovalByFilter = async (status: Status, onboardingBypass?: boolean) => {
    setIsLoading((prev) => ({ ...prev, [status]: true }));

    const hasOnboardingRule = onboardingRule && status === Status.APPROVED;
    const params = mappedByFilter(status, hasOnboardingRule ? !!onboardingBypass : false);

    onUpdateByFilter({
      params,
      toasts: {
        message: `massApproval.toasts.${status}`,
        attributes: {
          value: counter,
        },
      },
    }).finally(() => setIsLoading((prev) => ({ ...prev, [status]: false })));
  };

  if (
    !permissions.has('actions.recommendation.approve') &&
    !permissions.has('actions.recommendation.deny')
  ) {
    return null;
  }

  return (
  <ApprovalButtonsContainer data-testid="approval-buttons-container">
      <Deny
        {...approvalProps}
        isLoading={isLoading.DENIED}
        portal={portal?.deny}
        onApproval={isAsyncMode ? onApprovalByFilter : onApproval}
      />
      
      {isRecommended && (
        <Approve
          {...approvalProps}
          isLoading={isLoading.APPROVED}
          portal={portal?.approve}
          onApproval={isAsyncMode ? onApprovalByFilter : onApproval}
        />
      )}
    </ApprovalButtonsContainer>
  );
};
