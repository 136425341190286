import { ListItem, Paragraph } from '@hexa-ui/components';
import { Edit2, Lock, MoreHorizontal, X } from '@hexa-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollapseActionsMenu } from '../../../../../molecules/CollapseActionsMenu/CollapseActionsMenu';
import { Wrapper } from '../../../../../molecules/CollapseActionsMenu/styles';
import { ActiveProps, EventType, ModalTypes } from './Active.types';

export const Active: React.FC<ActiveProps> = ({ selected, disabled = false, handleOpenModal }) => {
  const { formatMessage } = useIntl();

  const onTrigger = (event: EventType, type: ModalTypes) => {
    event.stopPropagation();

    handleOpenModal?.(selected, type);
  };

  return (
    <CollapseActionsMenu disabled={disabled} icon={MoreHorizontal}>
      <ListItem
        id="edit"
        variant="selectable"
        style={{ listStyle: 'none' }}
        onClick={(event: EventType) => onTrigger(event, ModalTypes.EDIT_CREDIT_LINE)}
      >
        <Wrapper>
          <Edit2 size="large" />
          <Paragraph>{formatMessage({ id: 'tables.collapseActionsMenu.edit' })}</Paragraph>
        </Wrapper>
      </ListItem>

      <ListItem
        id="block"
        variant="selectable"
        style={{ listStyle: 'none' }}
        onClick={(event: EventType) => onTrigger(event, ModalTypes.BLOCK_CREDIT_LINE)}
      >
        <Wrapper>
          <Lock size="large" />
          <Paragraph>{formatMessage({ id: 'tables.collapseActionsMenu.block' })}</Paragraph>
        </Wrapper>
      </ListItem>

      <ListItem
        id="cancel"
        variant="selectable"
        style={{ listStyle: 'none' }}
        onClick={(event: EventType) => onTrigger(event, ModalTypes.CANCEL_CREDIT_LINE)}
      >
        <Wrapper>
          <X size="large" />
          <Paragraph>{formatMessage({ id: 'tables.collapseActionsMenu.cancel' })}</Paragraph>
        </Wrapper>
      </ListItem>
    </CollapseActionsMenu>
  );
};
