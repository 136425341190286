import { IconButton } from '@hexa-ui/components';
import { IconProps } from '@hexa-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import { DisabledWrapper } from './styles';

interface CollapseActionsMenuProps {
  disabled?: boolean;
  children: React.ReactNode;
  icon: React.FunctionComponent<IconProps>;
}

export const CollapseActionsMenu = ({
  disabled,
  children,
  icon: Icon,
}: CollapseActionsMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const clickOutsideDropdown =
      dropdownRef.current && !dropdownRef.current.contains(event.target as Node);

    if (clickOutsideDropdown) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <Dropdown parentRef={buttonRef} isOpen={isOpen} ref={dropdownRef}>
        {children}
      </Dropdown>

      <DisabledWrapper $disabled={disabled}>
        <IconButton
          id="dots-icon-button"
          ref={buttonRef}
          size="small"
          disabled={disabled}
          data-testid="dots-icon-button"
          icon={() => <Icon size="medium" />}
          variant="tertiary"
          onClick={handleClick}
        />
      </DisabledWrapper>
    </>
  );
};
