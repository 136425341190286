import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 16px 14px;
  font-size: 16px;
  border-radius: 8px;
  p {
    font-size: 16px;
  }
`;

export const DisabledWrapper = styled.div<{ $disabled: boolean }>`
  svg {
    path {
      fill: ${({ $disabled }) => ($disabled ? 'rgba(20, 20, 20, 0.32)' : 'rgba(20, 20, 20, 1)')};
    }
  }
`;
