import { ManagementItem } from '../../../interfaces';
import BlockCreditLineModal from '../BlockCreditLineModal/BlockCreditLineModal';
import CancelCreditLineModal from '../CancelCreditLineModal/CancelCreditLineModal';
import EditCreditLineModal from '../EditCreditLineModal/EditCreditLineModal';

interface ActiveCreditLineModalsWrapperProps {
  selected: ManagementItem;
  isOpen: {
    cancelCreditLine: boolean;
    editCreditLine: boolean;
    blockCreditLine: boolean;
  };
  onClose: () => void;
}

const ActiveCreditLineModalsWrapper: React.FC<ActiveCreditLineModalsWrapperProps> = ({
  selected,
  isOpen,
  onClose,
}) => {
  return (
    <>
      {isOpen.cancelCreditLine && (
        <CancelCreditLineModal data={selected} mode="single" onClose={onClose} />
      )}
      {isOpen.blockCreditLine && (
        <BlockCreditLineModal creditLineData={selected} onClose={onClose} />
      )}
      {isOpen.editCreditLine && <EditCreditLineModal creditLineData={selected} onClose={onClose} />}
    </>
  );
};

export default ActiveCreditLineModalsWrapper;
