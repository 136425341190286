import { SortingState } from '@tanstack/react-table';
import { Pagination, ServicesProps, ServicesResponse } from '../../interfaces';

interface ManagementPropsServiceProps {
  status: string;
  search?: string;
  filters?: string;
  pagination?: Pagination;
  sort?: SortingState;
}

export interface ManagementProps extends ServicesProps {
  params: ManagementPropsServiceProps;
}

interface BlockCreditLineProps extends ServicesProps {
  params: { id: string; blockReason: string };
}

interface CancelCreditLineProps extends ServicesProps {
  params: { id: string; cancelReason: string };
}

const service = {
  getManagementData: async ({
    api,
    params,
  }: Partial<ManagementProps>): Promise<ServicesResponse | null> => {
    const mappedFields = {
      id: 'data.id',
      beesAccountId: 'data.beesAccountId',
      accountId: 'data.accountId',
      pocName: 'data.pocName',
      creditLimit: 'data.creditLimit',
      term: 'data.term',
      fee: 'data.fee',
      score: 'data.score',
      paymentStatus: 'data.paymentStatus',
      updatedAt: 'data.updatedAt',
      updatedBy: 'data.updatedBy',
    };

    try {
      const { sort } = params;
      const sortParams = sort
        .map(
          (sortParam) =>
            `sortField=${mappedFields[sortParam.id]}&sortDirection=${
              sortParam.desc ? 'desc' : 'asc'
            }`
        )
        .join('&');

      const queryParams: string[] = [...(sortParams.length > 0 ? [sortParams] : [])];

      const response = await api.get({ path: `/v1/management?${queryParams.join('&')}` });

      if (!response?.data) throw new Error(response.message);

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  getManagementSummary: async ({
    api,
    params,
  }: Partial<ManagementProps>): Promise<ServicesResponse | null> => {
    try {
      const response = await api.get({
        path: '/v1/management/summary',
        config: {
          params: {
            processing: 'PROCESSING',
            active: 'ACTIVE',
            blocked: 'BLOCKED',
            cancelled: 'CANCELLED',
          },
        },
      });

      if (!response?.data) throw new Error(response.message);

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  blockCreditLine: async ({
    api,
    params,
  }: BlockCreditLineProps): Promise<ServicesResponse | null> => {
    try {
      const { id, blockReason } = params;

      const response = await api.post({
        path: `/v1/management/block/${id}`,
        body: { blockReason },
      });

      if (response.hasError) throw new Error(response.message);

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
  cancelCreditLine: async ({
    api,
    params,
  }: CancelCreditLineProps): Promise<ServicesResponse | null> => {
    try {
      const { id, cancelReason } = params;

      const response = await api.post({
        path: `/v1/management/cancel/${id}`,
        body: { cancelReason },
      });

      if (response.hasError) throw new Error(response.message);

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
};

export const { getManagementData, getManagementSummary, cancelCreditLine, blockCreditLine } =
  service;
