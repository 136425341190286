import { Card } from '@hexa-ui/components';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  position: absolute;

  li > div:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  li > div:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;
