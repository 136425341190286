import { Button, Divider, Heading, Paragraph } from '@hexa-ui/components';
import { PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useMemo, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useFormatting } from '../../../hooks/useFormatting';
import { useGetSources } from '../../../hooks/useGetSources';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Input } from '../../atoms/Input/Input';
import { AlertStyled } from '../BlockCreditLineModal/styles';
import { Modal } from '../Modal/Modal';
import {
  EditCreditLineModalProps,
  EditState,
  InitialMapping,
  PayloadEdit,
} from './EditCreditLineModal.types';
import { InputContainer, ModalContent } from './styles';

const EditCreditLineModal: React.FC<EditCreditLineModalProps> = ({ onClose, creditLineData }) => {
  const { formatMessage } = useIntl();

  const { currency, configs } = useGetUserPreferences();
  const { getValidation } = useGetSources();
  const { formatting } = useFormatting();

  const { validations } = configs;

  const reducer = useCallback((state: EditState, { type, payload }: PayloadAction<PayloadEdit>) => {
    if (type === 'CLEAR' || !payload) return initial;

    const { has, message } = getValidation(
      payload.id,
      payload.state.value === '00' ? '' : payload.state.value,
      validations[payload.id]
    );

    if (type === 'SETTER' && payload.state.value && payload.state.value !== '00') {
      return {
        ...state,
        [payload.id]: {
          value: payload.state.value,
          formatted: payload.state.formatted,
          error: { has, message },
        },
      };
    }

    return {
      ...state,
      [payload.id]: {
        value: '',
        formatted: { simple: '', completed: '' },
        error: { has, message },
      },
    };
  }, []);

  const initial = useMemo(() => {
    const initalMapping: InitialMapping = {
      limit: { type: 'currency', text: creditLineData.creditLimit ?? '' },
      term: { type: 'days', text: creditLineData.term ?? '' },
      'term.Fee': { type: 'fee', text: creditLineData.fee ?? '' },
    };

    return Object.entries(initalMapping).reduce((acc, [key, { type, text }]) => {
      const { value, formatted } = formatting({ type, value: text });

      acc[key] = {
        value,
        formatted: formatted,
        error: { has: false, message: '' },
      };

      return acc;
    }, {});
  }, [creditLineData.creditLimit, creditLineData.term, creditLineData.fee]);

  const [state, dispatch] = useReducer(reducer, initial);

  const { isDisabled } = useMemo(() => {
    const keyMapping: { [key: string]: string } = {
      limit: 'creditLimit',
      term: 'term',
      'term.Fee': 'fee',
    };

    const { hasErrors, allEqual } = Object.entries(state as EditState).reduce(
      (acc, [key, { error, value }]) => {
        acc.hasErrors.push(error.has || !value);
        acc.allEqual.push(value === creditLineData[keyMapping[key]]);

        return acc;
      },
      { hasErrors: [], allEqual: [] }
    );

    return {
      isDisabled: hasErrors.some((error) => error) || allEqual.every((equal) => equal),
      allEqual: allEqual.every((equal) => equal),
    };
  }, [state]);

  const onClear = () => {
    dispatch({ type: 'CLEAR', payload: null });

    onClose();
  };

  const onChange = useCallback(({ id, value, formatted }) => {
    dispatch({ type: 'SETTER', payload: { id, state: { value, formatted } } });
  }, []);

  return (
    <Modal
      data-testid="edit-credit-line-modal"
      actions={{
        buttons: {
          cancel: (
            <Button id="go-back" size="large" variant="secondary" onClick={onClear}>
              {formatMessage({ id: 'editCreditLineModal.buttons.back' })}
            </Button>
          ),
          confirm: (
            <Button
              id="on-apply"
              size="large"
              disabled={isDisabled}
              variant="primary"
              onClick={onClear}
            >
              {formatMessage({ id: 'editCreditLineModal.buttons.apply' })}
            </Button>
          ),
        },
      }}
      title={<Heading size="H2">{formatMessage({ id: 'editCreditLineModal.title' })}</Heading>}
      portal={{ has: true, container: document.body }}
      onClose={onClear}
      open={{ value: true, setter: () => {} }}
      headerHeight="4.5rem"
      onInteractOutside={onClear}
    >
      <ModalContent data-testid="modal-content" onClick={(e) => e.stopPropagation()}>
        <Heading size="H4" css={{ marginTop: '8px', whiteSpace: 'pre' }}>
          {`${creditLineData.pocName}  |  ID: ${creditLineData.accountId}`}
        </Heading>
        <Paragraph size="basis">
          {formatMessage({ id: 'editCreditLineModal.description' })}
        </Paragraph>
        <Divider />

        <InputContainer data-testid="input-container">
          <Input.Text
            id="limit"
            format="currency"
            size="large"
            width="100%"
            prefix={currency.config.symbol}
            label={formatMessage({ id: 'editCreditLineModal.inputs.creditLimit' })}
            error={{ has: state['limit'].error.has, message: state['limit'].error.message }}
            value={state['limit'].formatted.simple}
            onChange={onChange}
          />

          <Input.Text
            id="term"
            format="days"
            size="large"
            width="100%"
            suffix={formatMessage(
              { id: 'formatting.input.suffix.days' },
              { value: state['term'].formatted.simple }
            )}
            label={formatMessage({ id: 'editCreditLineModal.inputs.maximumCrediTerm' })}
            error={{ has: state['term'].error.has, message: state['term'].error.message }}
            value={state['term'].formatted.simple}
            onChange={onChange}
          />

          <Input.Text
            id="term.Fee"
            format="percentage"
            size="large"
            width="100%"
            suffix={formatMessage({ id: 'formatting.input.suffix.percentage' })}
            label={formatMessage({ id: 'editCreditLineModal.inputs.creditFee' })}
            error={{ has: state['term.Fee'].error.has, message: state['term.Fee'].error.message }}
            value={state['term.Fee'].formatted.simple}
            onChange={onChange}
          />
        </InputContainer>

        <AlertStyled
          componentVariant="alert"
          data-testid="alert"
          message={formatMessage({ id: 'editCreditLineModal.alert' })}
          type="info"
        />
      </ModalContent>
    </Modal>
  );
};

export default EditCreditLineModal;
