import { ManagementItem } from '../../../../../../interfaces';

export enum ModalTypes {
  CANCEL_CREDIT_LINE = 'cancelCreditLine',
  BLOCK_CREDIT_LINE = 'blockCreditLine',
  EDIT_CREDIT_LINE = 'editCreditLine',
}

export interface ActiveProps {
  selected: ManagementItem;
  disabled?: boolean;
  handleOpenModal?: (item: ManagementItem, modal: ModalTypes) => void;
}

export type EventType = React.MouseEvent<HTMLLIElement, MouseEvent>;
